<template>
    <div>
         <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>内容管理</el-breadcrumb-item>
            <el-breadcrumb-item>小视频</el-breadcrumb-item>
        </el-breadcrumb>
        <el-table
            :data="tableData"
            border
            style="width: 100%">
            <el-table-column
            prop="date"
            label="日期"
            width="180">
            </el-table-column>
            <el-table-column
            prop="name"
            label="姓名"
            width="180">
            </el-table-column>
            <el-table-column
            prop="address"
            label="地址">
            </el-table-column>
        </el-table>
       <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400">
            </el-pagination>
    </div>
</template>

<script>
export default {

    data(){
        return{
            tabledete:[],//分页数据
            total:0,//总共几条
            pageindex:1,//当前第几页
            pagesize:20,//每页多少条

        }
    },
    methods:{
       handleSizeChange:function (e) {//改变每页几条的数据监听方法
           
       },
       handleCurrentChange:function (e) {//切换第几页的监听方法
           
       }
    }
    
}
</script>

<style lang="less" scoped>

</style>